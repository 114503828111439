import React from 'react';
import { getDevicesApi, addDeviceApi, editObjectApi } from '../api/api';
import BtnFilled from '../components/BtnFilled';

import Input from '../components/Input';
import Dropdown from '../components/Dropdown';

const ObjectEditor = ({ setObject, object }) => {
  const [devices, setDevices] = React.useState([]); //main variable
  const [devicesErr, setDevicesErr] = React.useState([]); //server respond variable
  const getDevices = async () => {
    await getDevicesApi(
      function (data) {
        setDevices(data);
      },
      function (err) {
        setDevicesErr((p) => [...p, err]);
      }
    );
  };

  React.useEffect(() => {
    getDevices();
  }, []);

  const setDevData = (e) => {
    //var devToAdd = {};
    //devices.map((dev, i) => {
    //  if (dev.id === e) devToAdd = dev;
    //});
    //
    //
    //devToAdd.device_id = devToAdd.id;
    //
    //setInputToAdd({ ...inputToAdd, ...devToAdd });
  };

  const onDevInputAdd = () => {
    //var found = false;
    //setError([]);
    //deviceInputs.map((input, i) => {
    //  if (input.input_number === inputToAdd.input_number) {
    //    found = true;
    //    setError((p) => [...p, { error: 'Выберите свободный канал' }]);
    //  }
    //  if (input.device_id === inputToAdd.device_id) {
    //    found = true;
    //    setError((p) => [...p, { error: 'Выберите свободное устройство' }]);
    //  }
    //});
    //
    //if (!found) setDeviceInputs([...deviceInputs, inputToAdd]);
  };

  React.useEffect(() => {
    getDevices();
  }, []);
  return (
    <>
      <table class="table-auto w-full text-base mb-4">
        <thead>
          <tr>
            <th>Список привязанных устройств</th>
          </tr>
        </thead>
        <tbody>
          <tr key={1}>
            <td class="text-center border-y">{'A500'}</td>
          </tr>
        </tbody>
      </table>
      <div class="w-full mt-4">
        <Dropdown
          label={'Выбрать устройство'}
          options={devices}
          inputCb={(e) => setDevData(parseInt(e.target.value))}
          id="devs"
          valkey="id"
          optkey="serial_number"></Dropdown>
      </div>
      <div class="w-full mt-4">
        <BtnFilled title="Добавить" btnCb={() => onDevInputAdd()}></BtnFilled>
      </div>
    </>
  );
};

export default ObjectEditor;
